<div id="container">
    <h2>
        Registrace
    </h2>
    <div id="blok">
        <app-loading *ngIf="isLoading"></app-loading>
        <form name="form" (ngSubmit)="f.valid && signup()" #f="ngForm" novalidate>
            <div class="form-group">
                <label for="email">
                    Email
                </label>
                <input type="email" class="form-control" required email [(ngModel)]="email" name="email"
                    #emailInput="ngModel">
            </div>
            <div *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)" class="alert alert-danger">
                <div *ngIf="emailInput.errors?.['required']">
                    Email je povinný.
                </div>
                <div *ngIf="emailInput.errors?.['email']">
                    Email není platný.
                </div>
            </div>
            <div class="form-group">
                <label for="username">
                    Username
                </label>
                <input type="username" class="form-control" required [(ngModel)]="username" name="username"
                    minlength="5" #usernameInput="ngModel">
            </div>
            <div *ngIf="usernameInput.invalid && (usernameInput.dirty || usernameInput.touched)"
                class="alert alert-danger">
                <div *ngIf="usernameInput.errors?.['required']">
                    Username je povinné.
                </div>
                <div *ngIf="usernameInput.errors?.['minlength']">
                    Username musí mít více než 4 znaky.
                </div>
            </div>
            <div class="form-group">
                <label for="password">
                    Heslo
                </label>
                <div id="password_container_1">
                    <input type="{{ showPassword0 ? 'text' : 'password' }}" class="form-control" required
                        [(ngModel)]="password0" name="password0" minlength="9" #passwordInput0="ngModel">
                    <i class="material-icons" matSuffix (click)="showPassword0 = !showPassword0">
                        {{ showPassword0 ? 'visibility' : 'visibility_off' }}
                    </i>
                </div>
                <div *ngIf="passwordInput0.invalid && (passwordInput0.dirty || passwordInput0.touched)"
                    class="alert alert-danger">
                    <div *ngIf="passwordInput0.errors?.['required']">
                        Heslo je povinné.
                    </div>
                    <div *ngIf="passwordInput0.errors?.['minlength']">
                        Heslo musí mít více než 8 znaků.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="password1">
                    Zopakujte heslo
                </label>
                <div id="password_container_2">
                    <input type="{{ showPassword1 ? 'text' : 'password' }}" class="form-control" required
                        [(ngModel)]="password1" name="password1" minlength="9" #passwordInput1="ngModel">
                    <i class="material-icons" matSuffix (click)="showPassword1 = !showPassword1">
                        {{ showPassword1 ? 'visibility' : 'visibility_off' }}
                    </i>
                </div>
                <div *ngIf="passwordInput1.invalid && (passwordInput1.dirty || passwordInput1.touched)"
                    class="alert alert-danger">
                    <div *ngIf="passwordInput1.errors?.['required']">
                        Heslo je povinné.
                    </div>
                    <div *ngIf="passwordInput1.errors?.['minlength']">
                        Heslo musí mít více než 8 znaků.
                    </div>
                </div>
                <div *ngIf="(!passwordInput1.errors?.['minlength'] && (password0 !== password1)) && password1"
                    class="alert alert-danger">
                    Hesla se neshodují.
                </div>
            </div>
            <br>
            <button type="submit" class="btn btn-primary">
                Registrovat se
            </button>
        </form>
    </div>
</div>