<div id="container">
    <h2>
        Změna hesla
    </h2>
    <div id="blok">
        <form (ngSubmit)="f.valid && changePassword()" #f="ngForm" novalidate>
            <div class="form-group">
                <label for="password">
                    Nové Heslo
                </label>
                <div id="password_container_1">
                    <input type="{{ showPassword0 ? 'text' : 'password' }}" class="form-control" required
                        [(ngModel)]="password0" name="password0" minlength="8" #passwordInput0="ngModel">
                    <i class="material-icons" matSuffix (click)="showPassword0 = !showPassword0">
                        {{ showPassword0 ? 'visibility' : 'visibility_off' }}
                    </i>
                </div>
                <div *ngIf="passwordInput0.invalid && (passwordInput0.dirty || passwordInput0.touched)"
                    class="alert alert-danger">
                    <div *ngIf="passwordInput0.errors?.['required']">
                        Heslo je povinné.
                    </div>
                    <div *ngIf="passwordInput0.errors?.['minlength']">
                        Heslo musí mít alespoň 8 znaků.
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="password1">
                    Zopakujte nové heslo
                </label>
                <div id="password_container_2">
                    <input type="{{ showPassword1 ? 'text' : 'password' }}" class="form-control" required
                        [(ngModel)]="password1" name="password1" minlength="8" #passwordInput1="ngModel">
                    <i class="material-icons" matSuffix (click)="showPassword1 = !showPassword1">
                        {{ showPassword1 ? 'visibility' : 'visibility_off' }}
                    </i>
                </div>
                <div *ngIf="passwordInput1.invalid && (passwordInput1.dirty || passwordInput1.touched)"
                    class="alert alert-danger">
                    <div *ngIf="passwordInput1.errors?.['required']">
                        Heslo je povinné.
                    </div>
                    <div *ngIf="passwordInput1.errors?.['minlength']">
                        Heslo musí mít alespoň 8 znaků.
                    </div>
                </div>
                <div *ngIf="(!passwordInput1.errors?.['minlength'] && (password0 !== password1)) && password1"
                    class="alert alert-danger">
                    Hesla se neshodují.
                </div>
            </div>
            <br>
            <div type="submit" class="btn btn-primary">
                Změnit heslo
            </div>
        </form>
    </div>
</div>