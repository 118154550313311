<div id="container" *ngIf="styl">
    <h2>
        {{ styl.title }}
    </h2>
    <div id="columns">
        <div id="first_column">
            <div id="direction_row">
                <div id="direction_column">
                    <h4>
                        {{ 'SLOVNIK.slovnikZkratka' | translate }}
                    </h4>
                    <div id="pole_zkratka">
                        <div id="zkratka">
                            {{ styl.zkratka }}
                        </div>
                    </div>
                </div>
                <div id="direction_column">
                    <h4>
                        {{ 'SLOVNIK.image' | translate }}
                    </h4>
                    <img id="znacka" src="assets/icon/svg/{{ styl.nazev }}.svg">
                </div>
            </div>
            <h5>
                <strong>
                    {{ 'SLOVNIK.animace' | translate }}
                </strong>
            </h5>
            {{ styl.popis }}
        </div>
        <div id="second_column">
            <h2>
                {{ 'SLOVNIK.animace' | translate }}
            </h2>
            <img id="size_1" src="assets/icon/gif/{{ styl.nazev }}.gif">
            <h2>
                {{ 'SLOVNIK.veSkutecnosti' | translate }}
            </h2>
            <img id="size_2" src="assets/icon/jpg/{{ styl.nazev }}-real.jpg">
        </div>
    </div>
</div>