<div id="container" *ngIf="navod">
    <h2>
        {{ 'NAVODY.navody' | translate }}
    </h2>
    <div id="sloupce">
        <div id="bunka_{{ item.difficulty }}" *ngFor="let item of navod" (click)="goKroky(item.id)">
            <div id="okraj_{{ item.difficulty }}">
                {{ getTitle(item) }}
                <div id="svgs" *ngIf="item.premium || item.finished">
                    <img *ngIf="item.premium" src="assets/icon/svg/premium.svg">
                    <img *ngIf="item.finished" src="assets/icon/svg/finished.svg">
                </div>
            </div>
            <div id="obsah">
                <img id="obrazek_navodu" src="{{ item.link || 'assets/icon/svg/picture.svg' }}">
                <div id="text">
                    <strong>
                        {{ 'NAVODY.pocetCasti' | translate }}
                    </strong>
                    {{ item.steps.length-1 }}
                    <br>
                    <strong>
                        {{ 'NAVODY.stylyZkratky' | translate }}
                    </strong>
                    {{ getShortcuts(item) }}
                </div>
            </div>
        </div>
    </div>
</div>