<div id="container">
  <h2>
    Přihlášení
  </h2>
  <div id="blok">
    <app-loading *ngIf="isLoading"></app-loading>
    <form name="form" (ngSubmit)="f.valid && login()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="email">
          Email nebo username
        </label>
        <input type="text" class="form-control" required [(ngModel)]="email" name="email">
      </div>
      <div class="form-group">
        <label for="password">
          Heslo
        </label>
        <div id="password_container">
          <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control" id="password" required
            [(ngModel)]="password" name="password">
          <i class="material-icons" matSuffix (click)="showPassword = !showPassword">
            {{ showPassword ? 'visibility' : 'visibility_off' }}
          </i>
        </div>
      </div>
      <br>
      <button type="submit" class="btn btn-primary">
        Přihlásit
      </button>
    </form>
    Ještě nemáte účet?
    <a routerLink="/registrace">
      Zaregistruj se!
    </a>
    <br>
    <strong>
      Zapomněli jste heslo?
    </strong>
    <form name="form1" (ngSubmit)="f1.valid && sendPasswordChange()" #f1="ngForm" novalidate>
      <div class="form-group">
        <label for="email1">
          Zadejte email pro obnovení hesla.
        </label>
        <input type="email" class="form-control" id="email1" email [(ngModel)]="email1" name="email1"
          #emailInput1="ngModel">
      </div>
      <div *ngIf="emailInput1.invalid && (emailInput1.dirty || emailInput1.touched)" class="alert alert-danger">
        <div *ngIf="emailInput1.errors?.['email']">
          Email není platný.
        </div>
      </div>
      <br>
      <button type="submit" class="btn btn-primary">
        Reset hesla
      </button>
    </form>
  </div>
</div>