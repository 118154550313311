<div id="container" *ngIf="vyuka">
    <h2>
        {{ 'VYUKOVYMOD.vyukovyMod' | translate }}
    </h2>
    <div id="sloupce">
        <div id="bunka" *ngFor="let item of vyuka" (click)="goKroky(item.nazev)">
            <div id="okraj">
                {{ item.title }}
            </div>
            <div id="obsah">
                <img id="obrazek_navodu" [src]="'assets/icon/jpg/' + item.nazev + '.jpg'">
                <div id="text">
                    <strong>
                        {{ 'VYUKOVYMOD.pocetKroku' | translate }}
                    </strong>
                    {{ item.pocet }}
                    <br>
                    <strong>
                        {{ 'VYUKOVYMOD.popis' | translate }}
                    </strong>
                    {{ item.popis }}
                </div>
            </div>
        </div>
    </div>
</div>