<div id="container" *ngIf="navod && krokyNavodu">
    <h2>
        {{ getTitle(navod) }}
    </h2>
    <div id="columns">
        <div id="column_1">
            <div id="blok_1">
                <strong>
                    {{ 'NAVODYDETAIL.pouzitiStylu' | translate }}
                </strong>
                {{ getShortcuts(navod) }}
                <img id="obrazek_navodu" src="{{ navod.link || 'assets/icon/svg/picture.svg' }}">
            </div>
            <div id="blok_2">
                <div id="button" *ngFor="let item of krokyNavodu" (click)="setIndex(item.id, navod.id)" #krokyElement>
                    {{ getTitle(item) }}
                </div>
            </div>
        </div>
        <div id="column_2">
            <strong>
                {{ getTitle(krokyNavodu[index]) }}:
            </strong>
            <div id="items" *ngFor="let item of getStepsDescription(krokyNavodu[index])" #items>
                {{ item }}
            </div>
            <div id="buttons">
                <img id="button_1" src="assets/icon/svg/Vlevo.svg" (click)="previousIndex()">
                <div id="button_2" (click)="startStopTimer()">
                    <img id="play" [src]="isRunning ? 'assets/icon/svg/pause.svg' : 'assets/icon/svg/play.svg'">
                    <div id="time">
                        {{ minutes }}:{{ seconds }}
                    </div>
                </div>
                <div id="button_3" (click)="finished()">
                    {{ 'NAVODYDETAIL.hotovo' | translate }}
                </div>
                <img id="button_4" src="assets/icon/svg/Vpravo.svg" (click)="nextIndex()">
            </div>
        </div>
    </div>
</div>