<div id="container" *ngIf="infoVyuky && title">
    <h2>
        {{ title }}
    </h2>
    <div id="columns">
        <div id="column_1">
            <strong>
                {{ 'VYUKOVYMODDETAIL.rada' | translate }}
            </strong>
            {{infoVyuky[index].cislo}}
            <br>
            <strong>
                {{ 'VYUKOVYMODDETAIL.pomucky' | translate }}
            </strong>
            {{ infoVyuky[index].pouzijeme }}
            <div id="popis" innerHTML="{{ infoVyuky[index].popis }}">
                <strong>
                    {{ 'VYUKOVYMODDETAIL.popis' | translate }}
                </strong>
            </div>
        </div>
        <iframe id="column_2" [src]="infoVyuky[index].videoUrl | safe" width="560" height="315" frameborder="0"
            allowfullscreen>
        </iframe>
    </div>
    <div id="buttons">
        <img id="button_1" src="assets/icon/svg/Vlevo.svg" (click)="changeIndex(false)">
        {{ index+1 }}/{{ infoVyuky.length }}
        <img id="button_2" src="assets/icon/svg/Vpravo.svg" (click)="changeIndex(true)">
    </div>
</div>