<div id="container">
    <div id="novinky">
        <h1>
            <strong>
                {{ 'HOME.uvitani' | translate }}
            </strong>
        </h1>
        {{ 'HOME.webovaAplikace' | translate }}
        <br>
        <br>
        {{ 'HOME.mobilniAplikace' | translate }}
    </div>
    <div id="obrazky">
        <img id="obrazek_1" src="assets/icon/jpg/slon.jpg">
        <img id="obrazek_2" src="assets/icon/jpg/chobotnicka.jpg">
        <img id="obrazek_3" src="assets/icon/jpg/medved.jpg">
    </div>
    <h2>
        <strong>
            {{ 'HOME.socialniSite' | translate }}
        </strong>
    </h2>
    <div id="soc_site">
        <a id="card_1" href="mailto:info@selecro.cz">
            <img id="socials_logo_1" src="assets/icon/svg/mail.svg">
        </a>
        <a id="card_2" href="https://www.instagram.com/_selecro_/" target="_blank">
            <img id="socials_logo_2" src="assets/icon/svg/instagram.svg">
        </a>
        <a id="card_3" href="https://www.pinterest.com/selecro/" target="_blank">
            <img id="socials_logo_3" src="assets/icon/svg/pin.svg">
        </a>
    </div>
</div>